var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "tableRisk",
        attrs: {
          title: "LBL0001203",
          tableId: "riskRegister01",
          columns: _vm.gridRisk.columns,
          data: _vm.riskReduce.riskbooks,
          merge: _vm.gridRisk.merge,
          gridHeightAuto: "",
          filtering: false,
          columnSetting: false,
          usePaging: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "ramChecklistAssessScenarioId",
                          ibmTaskTypeCd: "ITT0000010",
                          ibmTaskUnderTypeCd: "ITTU000010",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : col.name === "picture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-primary",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openImprPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "checkItemName"
                  ? [
                      props.row.checkItemId &&
                      props.row.ramCheckItemClassCd !== "RCIC000015"
                        ? [
                            _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "left",
                                color: "blue-6",
                                label: props.row[col.name],
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openItem(props.row)
                                },
                              },
                            }),
                          ]
                        : [_vm._v(" " + _vm._s(props.row[col.name]) + " ")],
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        ref: "tableImpr",
        attrs: {
          title: "LBL0001174",
          tableId: "impr01",
          columns: _vm.gridImpr.columns,
          data: _vm.riskReduce.imprs,
          merge: _vm.gridImpr.merge,
          gridHeightAuto: "",
          filtering: false,
          columnSetting: false,
          usePaging: false,
        },
        on: { linkClick: _vm.linkClick },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "picture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-primary",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openImprPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "checkItemName"
                  ? [
                      props.row.checkItemId &&
                      props.row.ramCheckItemClassCd !== "RCIC000015"
                        ? [
                            _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "left",
                                color: "blue-6",
                                label: props.row[col.name],
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openItem(props.row)
                                },
                              },
                            }),
                          ]
                        : [_vm._v(" " + _vm._s(props.row[col.name]) + " ")],
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }